'use client'

import {Accordion, AccordionItem} from "@nextui-org/react";
import {IconAlertCircle} from "@tabler/icons-react";

const significanceColors = {
    A: 'yellow',      // Watch
    B: 'purple',    // Bulletin
    L: 'green',     // Local Alerts
    M: 'gray',      // Message
    O: 'lightblue', // Outlook
    R: 'red',       // Report
    S: 'orange',    // Statement
    W: 'red',       // Warning
    Y: 'yellow'     // Advisory
};

export default function Alerts({alerts}: { alerts: any }) {
    return (<Accordion variant="shadow">
        {alerts.map((item: any) => (
            <AccordionItem key={item.detailKey} aria-label={item.eventDescription} title={item.eventDescription}
                           subtitle={`Area di ${item.areaName}`}
                           startContent={<IconAlertCircle
                               color={significanceColors[item.significance as keyof typeof significanceColors]} />}>
                <div className="flex flex-col gap-y-4">
                    <div className="text-lg font-bold text-left">
                        {item.details.texts[1].description}
                    </div>
                    <div
                        className="text-lg text-left">Inizio: <b>{new Date(item.effectiveTimeLocal).toLocaleDateString('it-IT', {
                        day: '2-digit', month: 'long', year: 'numeric'
                    })}</b> alle <b>{new Date(item.effectiveTimeLocal).toLocaleTimeString('it-IT', {
                        hour: '2-digit', minute: '2-digit'
                    })}</b></div>
                    <div
                        className="text-lg text-left">Fine: <b>{new Date(item.expireTimeLocal).toLocaleDateString('it-IT', {
                        day: '2-digit', month: 'long', year: 'numeric'
                    })}</b> alle <b>{new Date(item.expireTimeLocal).toLocaleTimeString('it-IT', {
                        hour: '2-digit', minute: '2-digit'
                    })}</b></div>
                    <div className="text-left">
                        {item.details.texts[1].instruction}
                    </div>
                    <div className="text-sm text-left text-default-500">
                        Fonte: {item.officeName}
                    </div>
                </div>
            </AccordionItem>))}
    </Accordion>)
}