'use client'
import React, {useEffect, useRef, useState} from 'react';
import {Button, cn, Image} from "@nextui-org/react";
import NextImage from "next/image";
import {IconPlayerPause, IconPlayerPlay} from "@tabler/icons-react";
import {getCoverArt} from "@/api/radio";

export default function Player() {
    const [metadata, setMetadata] = useState({
        now_playing: {
            song: {
                artist: 'Stivor ETS',
                title: 'Radio Stivor',
            },
        },
    });
    const [releaseInfo, setReleaseInfo] = useState<any | null>(null);
    const [playing, setPlaying] = useState(false);
    const ref = useRef<HTMLAudioElement | null>(null);

    useEffect(() => {
        const sseBaseUri = 'https://radio.stivor.net/api/live/nowplaying/sse';
        const sseUriParams = new URLSearchParams({
            "cf_connect": JSON.stringify({
                "subs": {
                    "station:stivor": {"recover": true}
                }
            })
        });

        const eventSource = new EventSource(`${sseBaseUri}?${sseUriParams.toString()}`);

        let nowPlayingData: any = {};
        let currentTime = 0;

        const handleSseData = (ssePayload: any, useTime = true) => {
            const jsonData = ssePayload.data;
            if (!jsonData) return;

            if (useTime && 'current_time' in jsonData) {
                currentTime = jsonData.current_time;
            }

            nowPlayingData = jsonData.np || nowPlayingData;
            if (nowPlayingData.now_playing.song.artist !== metadata.now_playing.song.artist ||
                nowPlayingData.now_playing.song.title !== metadata.now_playing.song.title) {

                if (nowPlayingData.now_playing.song.artist.length === 0 || nowPlayingData.now_playing.song.title.length === 0) {
                    return;
                }

                if (nowPlayingData.now_playing.song.artist.toLowerCase().trim() === 'remote url') {
                    const remoteUrl = nowPlayingData.now_playing.song.title.split('-');
                    setMetadata({now_playing: {song: {artist: remoteUrl[0], title: remoteUrl[1]}}});
                    return;
                }

                setMetadata(nowPlayingData);
                fetchReleaseInfo(nowPlayingData.now_playing.song.artist, nowPlayingData.now_playing.song.title);
            }
        };

        const fetchReleaseInfo = (artist: string, title: string) => {
            const modifiedArtist = artist.toLowerCase().trim().replace(/(\B|^)(dj)(?=\w)/gi, 'đ');
            const modifiedTitle = title.toLowerCase().trim().replace(/(\B|^)(dj)(?=\w)/gi, 'đ');
            getCoverArt(modifiedArtist, modifiedTitle).then((releaseInfo) => {
                if (releaseInfo && releaseInfo.results && releaseInfo.results.length > 0) {
                    setReleaseInfo(releaseInfo.results[0]);
                } else {
                    setReleaseInfo(null);
                }
            }).catch(() => {
                setReleaseInfo(null);
            });
        };

        eventSource.onmessage = (event) => {
            const jsonData = JSON.parse(event.data);

            if ('connect' in jsonData) {
                const connectData = jsonData.connect;

                if ('data' in connectData) {
                    connectData.data.forEach((initialRow: any) => handleSseData(initialRow));
                } else {
                    if ('time' in connectData) {
                        currentTime = Math.floor(connectData.time / 1000);
                    }

                    for (const subName in connectData.subs) {
                        const sub = connectData.subs[subName];
                        if ('publications' in sub && sub.publications.length > 0) {
                            sub.publications.forEach((initialRow: any) => handleSseData(initialRow, false));
                        }
                    }
                }
            } else if ('pub' in jsonData) {
                handleSseData(jsonData.pub);
            }
        };

        eventSource.onerror = (err) => {
            console.error('Error:', err);
            eventSource.close();
        };

        fetchReleaseInfo(metadata.now_playing.song.artist, metadata.now_playing.song.title);

        return () => {
            eventSource.close();
        };
    }, [metadata]);

    const togglePlay = () => {
        if (!ref.current) return;

        if (playing) {
            ref.current.pause();
        } else {
            ref.current.load();
            ref.current.play();
        }

        setPlaying(!playing);
    };

    return (<div
            style={{
                backgroundImage: releaseInfo && releaseInfo?.artworkUrl30 ? `url(${releaseInfo.artworkUrl30})` : `url(/logo.jpg)`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
            }}
            className="w-full rounded-large dark:text-gray-800 shadow-lg hover:shadow-xl transition-shadow">
            <div
                className={'flex sm:p-8 py-4 rounded-large backdrop-blur-3xl backdrop-saturate-50 backdrop-brightness-50 border border-gray-200 border-opacity-25 flex-col sm:flex-row gap-4 items-center justify-center'}>
                <div className="flex justify-center items-center">
                    <Image
                        as={NextImage}
                        alt={releaseInfo?.collectionName ?? 'Radio Stivor'}
                        className="object-cover min-w-[192px] min-h-[192px] aspect-square"
                        height={192}
                        width={192}
                        isZoomed
                        sizes="(max-width: 768px) 100vw, 128px"
                        quality={50}
                        isLoading={!releaseInfo && !metadata}
                        src={releaseInfo?.artworkUrl100.replace('100x100', '500x500') ?? '/logo.jpg'}
                    />
                </div>

                <div className="flex flex-col justify-center gap-4">
                    <div className="flex justify-center items-center w-full">
                        <div className="flex flex-col gap-2 text-center">
                            <div
                                className={cn(
                                    'font-bold sm:text-2xl text-xl text-white truncate w-[250px] sm:w-[400px] md:w-[500px]',
                                    releaseInfo ? 'text-white' : '',
                                )}>
                                {metadata.now_playing.song.title}
                            </div>
                            <div
                                className={cn(
                                    'font-bold sm:text-xl text-lg text-white truncate w-[250px] sm:w-[400px] md:w-[500px]',
                                    releaseInfo ? 'text-white' : '',
                                )}>
                                {metadata.now_playing.song.artist}
                            </div>
                        </div>
                    </div>

                    <div className="flex w-full items-center justify-center">
                        <Button
                            isIconOnly
                            radius="full"
                            className={"p-4 w-auto h-auto border border-white border-opacity-50 text-white"}
                            size="lg"
                            variant="light"
                            onPress={togglePlay}
                        >
                            {playing ? (<IconPlayerPause size={48} />) : (<IconPlayerPlay size={48} />)}
                        </Button>
                    </div>
                </div>
            </div>
            <audio ref={ref} src="https://radio.stivor.net" preload="auto" />
        </div>
    );
}