'use client'
import React, {useCallback, useEffect, useState} from 'react'
import {EmblaCarouselType} from 'embla-carousel'
import Autoplay from 'embla-carousel-autoplay'
import useEmblaCarousel from 'embla-carousel-react'
import {IconPencil, IconChevronLeft, IconChevronRight} from "@tabler/icons-react";
import {Button} from "@nextui-org/react";
import Image from "next/image";
import {Image as ImageType} from "@prisma/client";
import {UserBase} from "@/config/interfaces";
import CarouselModal from "@/components/carousel-modal";

type UsePrevNextButtonsType = {
    prevBtnDisabled: boolean
    nextBtnDisabled: boolean
    onPrevButtonClick: () => void
    onNextButtonClick: () => void
}

const usePrevNextButtons = (
    emblaApi: any,
    onButtonClick?: (emblaApi: EmblaCarouselType) => void
): UsePrevNextButtonsType => {
    const [prevBtnDisabled, setPrevBtnDisabled] = useState(true)
    const [nextBtnDisabled, setNextBtnDisabled] = useState(true)

    const onPrevButtonClick = useCallback(() => {
        if (!emblaApi) return
        emblaApi.scrollPrev()
        if (onButtonClick) onButtonClick(emblaApi)
    }, [emblaApi, onButtonClick])

    const onNextButtonClick = useCallback(() => {
        if (!emblaApi) return
        emblaApi.scrollNext()
        if (onButtonClick) onButtonClick(emblaApi)
    }, [emblaApi, onButtonClick])

    const onSelect = useCallback((emblaApi: EmblaCarouselType) => {
        setPrevBtnDisabled(!emblaApi.canScrollPrev())
        setNextBtnDisabled(!emblaApi.canScrollNext())
    }, [])

    useEffect(() => {
        if (!emblaApi) return

        onSelect(emblaApi)
        emblaApi.on('reInit', onSelect).on('select', onSelect)
    }, [emblaApi, onSelect])

    return {
        prevBtnDisabled,
        nextBtnDisabled,
        onPrevButtonClick,
        onNextButtonClick
    }
}

interface CarouselProps {
    images: ImageType[];
    user?: UserBase | null;
    withArrows?: boolean;
}

const Carousel: React.FC<CarouselProps> = ({ images, user, withArrows = false }) => {
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [emblaRef, emblaApi] = useEmblaCarousel({loop: true, dragFree: false}, [Autoplay({
        playOnInit: true,
        delay: 3000
    })]);

    const onNavButtonClick = useCallback((emblaApi: EmblaCarouselType) => {
        const autoplay = emblaApi?.plugins()?.autoplay
        if (!autoplay) return

        const resetOrStop: any =
            autoplay.options.stopOnInteraction === false
                ? autoplay.reset
                : autoplay.stop

        resetOrStop()
    }, [])

    const {
        prevBtnDisabled,
        nextBtnDisabled,
        onPrevButtonClick,
        onNextButtonClick
    } = usePrevNextButtons(emblaApi, onNavButtonClick)

    return (
        <section className="mx-auto">
            {images.length === 0 && user && (user.role === 'ADMIN' || user.role === 'EXECUTIVE') && (
                <p>Nessuna immagine presente</p>
            )}
            <div className="relative overflow-hidden" ref={emblaRef}>
                <div
                    className="backface-hidden py-4 flex touch-pinch-zoom touch-pan-y -ml-[1rem]">
                    {images.map((image) => (
                        <div className="flex-shrink-0 min-w-0 pl-[1rem]"
                             style={{flex: "0 0 100%"}} key={image.id}>
                            <Image alt={image.title} src={`https://static.stivor.net/images/${image.path}`}
                                   height={1080} width={1920}
                                   style={{width: '100%'}} priority
                                   className="w-full h-full object-cover sm:rounded-large" />
                        </div>
                    ))}
                </div>
                {withArrows && (
                    <>
                        <Button
                            isIconOnly
                            className="absolute top-1/2 left-4 transform -translate-y-1/2 z-10"
                            onClick={onPrevButtonClick}
                            disabled={prevBtnDisabled}
                        >
                            <IconChevronLeft />
                        </Button>
                        <Button
                            isIconOnly
                            className="absolute top-1/2 right-4 transform -translate-y-1/2 z-10"
                            onClick={onNextButtonClick}
                            disabled={nextBtnDisabled}
                        >
                            <IconChevronRight />
                        </Button>
                    </>
                )}
            </div>

            {images.length > 0 ? (
                <div className="flex items-center justify-end gap-x-8 mt-4">
                    {user && (user.role === 'ADMIN' || user.role === 'EXECUTIVE') && (
                        <Button isIconOnly color="primary" aria-label="Modifica"
                                onPress={() => setIsEditModalOpen(true)}>
                            <IconPencil />
                            <CarouselModal images={images} isOpen={isEditModalOpen} setIsOpen={setIsEditModalOpen} />
                        </Button>
                    )}
                </div>
            ) : (
                <div className="flex items-center justify-center gap-x-8 mt-4">
                    {user && (user.role === 'ADMIN' || user.role === 'EXECUTIVE') && (
                        <Button color="primary" aria-label="Modifica"
                                onPress={() => setIsEditModalOpen(true)} startContent={<IconPencil />}>
                            Aggiungi immagini
                            <CarouselModal images={images} isOpen={isEditModalOpen} setIsOpen={setIsEditModalOpen} />
                        </Button>
                    )}
                </div>
            )}
        </section>
    )
}

export default Carousel;