'use client'
import React, {useCallback, useState} from "react";
import {Button, Card, CardBody} from "@nextui-org/react";
import Image from "next/image";
import {IconTrash} from "@tabler/icons-react";
import {ConfirmationModal} from "@/components/modal";
import {closestCenter, DndContext} from '@dnd-kit/core';
import {arrayMove, SortableContext, useSortable, verticalListSortingStrategy} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import {Image as ImageType} from "@prisma/client";
import {uploadFile} from "@/config/s3";
import {createImage, removeImage, updateImages} from "@/api/api";
import toast from "react-hot-toast";
import {useRouter} from "next/navigation";

export function SortableItem({id, children}: { id: string, children: React.ReactNode }) {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({id});

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    return (
        <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
            {children}
        </div>
    );
}

export default function CarouselModal({images, isOpen, setIsOpen}: {
    images: ImageType[], isOpen: boolean, setIsOpen: any
}) {
    const router = useRouter();
    const [items, setItems] = useState(images);

    const handleDragEnd = (event: { active: any; over: any }) => {
        const {active, over} = event;
        if (active.id !== over.id) {
            setItems((prevItems) => {
                const oldIndex = prevItems.findIndex(item => item.id.toString() === active.id);
                const newIndex = prevItems.findIndex(item => item.id.toString() === over.id);
                return arrayMove(prevItems, oldIndex, newIndex);
            });
        }
    };

    const handleFileDrop = useCallback(async (event: React.DragEvent) => {
        event.preventDefault();
        const files = Array.from(event.dataTransfer.files);

        if (!files.every(file => file.type.startsWith('image/'))) {
            toast.error('I file caricati devono essere immagini');
            return;
        }

        if (files.some(file => file.size > 10000000)) {
            toast.error('Le immagini caricate superano il limite massimo di 10MB');
            return;
        }

        const newItems: ImageType[] = [];
        const loading = toast.loading('Caricamento in corso...');
        for (const file of files) {
            let formData = new FormData();
            formData.append('file', file);
            const response = await uploadFile(formData, `images/home`);
            const newImage = await createImage({
                title: file.name,
                description: '',
                path: response.substring(response.lastIndexOf('/home/') + 1),
                order: items.length + 1,
                sectionId: 1
            });
            newItems.push(newImage.image);
        }

        setItems((prevItems) => [...prevItems, ...newItems]);
        router.refresh();

        toast.dismiss(loading);
        toast.success('Immagini caricate con successo!');
    }, [items]);

    const handleDragOver = (event: React.DragEvent) => {
        event.preventDefault();
    };

    return (
        <ConfirmationModal
            modalOpen={isOpen}
            setModalOpen={setIsOpen}
            onClose={() => setItems(images)}
            title="Modifica presentazione"
            message="Trascina qui le immagini da caricare"
            html={
                <div onDragOver={handleDragOver} onDrop={handleFileDrop}
                     className="relative min-h-[200px] flex flex-col justify-center items-center">
                    <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
                        <SortableContext items={items.map(item => item.id.toString())}
                                         strategy={verticalListSortingStrategy}>
                            <div
                                className="flex flex-col gap-y-4 w-full sm:max-h-[600px] max-h-[400px] overflow-y-auto p-4">
                                {items.map((image) => (
                                    <SortableItem key={image.id.toString()} id={image.id.toString()}>
                                        <Card className="w-full" isHoverable>
                                            <CardBody className="flex flex-row gap-x-4 items-center justify-start">
                                                <Image src={'https://static.stivor.net/images/' + image.path}
                                                       alt={image.title} width={64} height={64} />
                                                <div className="truncate mr-16">{image.title}</div>
                                                <Button color="danger" isIconOnly onPress={() => {
                                                    removeImage(image.id, image.path).then(() => {
                                                        setItems(items.filter((item) => item.id.toString() !== image.id.toString()));
                                                        toast.success('Immagine rimossa con successo!');
                                                    });
                                                }} className="absolute right-0 mr-4">
                                                    <IconTrash />
                                                </Button>
                                            </CardBody>
                                        </Card>
                                    </SortableItem>
                                ))}
                            </div>
                        </SortableContext>
                    </DndContext>
                </div>
            }
            button="Conferma"
            color="primary"
            onSubmit={() => {
                const orderedItems = items.map((item, index) => ({...item, order: index + 1}));
                updateImages(orderedItems).then(() => {
                    router.refresh();
                    setIsOpen(false);
                    toast.success('Immagini aggiornate con successo!');
                });
            }}
        />
    );
}