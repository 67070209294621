export async function getCoverArt(artist: string, title: string) {
    try {
        const response = await fetch(`https://itunes.apple.com/search?term=${encodeURIComponent(artist)}-${encodeURIComponent(title)}`);
        if (!response.ok) return null;

        const results = await response.json();
        if (!results.results || results.results.length === 0) return null;
        results.results.sort((a: any, b: any) => new Date(a.releaseDate).getTime() - new Date(b.releaseDate).getTime());

        return results;
    } catch (error) {
        console.error('Error fetching cover art:', error);
        return null;
    }
}