'use client'

import Image from "next/image";
import React, {useEffect, useState} from "react";
import Alerts from "@/components/alerts";
import {Button, Link, Popover, PopoverContent, PopoverTrigger} from "@nextui-org/react";
import {IconInfoCircle} from "@tabler/icons-react";
import {getWeather} from "@/api/api";

const Weather = ({alerts, data}: { alerts: any, data: any }) => {
    const [weather, setWeather] = useState(data);

    useEffect(() => {
        const fetchWeather = async () => {
            const weather = await getWeather();
            setWeather(weather);
        };

        fetchWeather();
        const interval = setInterval(fetchWeather, 120000);
        return () => clearInterval(interval);
    }, []);

    return (<div
        className="relative p-8 w-full sm:rounded-large dark:bg-gray-50 dark:text-gray-800 sm:border border-y border-gray-200 border-opacity-25 shadow-lg hover:shadow-xl transition-shadow">
        <Popover placement="bottom" showArrow>
            <PopoverTrigger>
                <Button
                    isIconOnly
                    startContent={<IconInfoCircle size={28} />}
                    color="default"
                    variant="flat"
                    className="absolute top-2 right-2 rounded-full">
                </Button>
            </PopoverTrigger>
            <PopoverContent className="p-4">
                <p>I dati meteo sono forniti da <Link
                    href={"https://weather.com/it-IT/tempo/oggi/l/877a40016ed753cd55794fcaa309a1470dd7ed5b3e0fde7de3c56003980d5db8"}
                    target="_blank"
                    isExternal
                    showAnchorIcon
                    className="text-sm">weather.com</Link></p>
            </PopoverContent>
        </Popover>

        <div className="text-2xl flex flex-row items-center justify-center text-center gap-x-2 font-bold">
            <span>Štivor</span>
            <div className="relative flex h-3 w-3">
                <div
                    className={`absolute inline-flex h-full w-full rounded-full opacity-75 ${weather && 'animate-ping bg-green-400'}`}></div>
                <div
                    className={`relative inline-flex rounded-full h-3 w-3 ${weather ? 'bg-green-500' : 'bg-red-400'}`}></div>
            </div>
        </div>

        <div className="flex flex-col justify-center space-y-2">
            {weather && (
                <div className="flex flex-row justify-center items-center space-x-4">
                    <Image src={`https://www.wunderground.com/static/i/c/v4/${weather.iconCode}.svg`}
                           aria-label={weather.wxPhraseLong} width={100} height={100}
                           alt={weather.wxPhraseLong} className="w-24 h-24 p-2" />
                    <span className="font-bold text-8xl">{weather.temperature}°</span>
                </div>
            )}
            <div className="text-center text-xl font-bold">
                {weather ? weather.wxPhraseLong : 'Meteo non disponibile.'}
            </div>
        </div>
        <div className="flex sm:flex-row flex-col justify-center items-center mt-4 gap-4">
            {weather.daypart[0].daypartName.slice(1, 5).map((part: string, index: number) => {
                const iconIndex = index + 1;
                const iconCode = weather.daypart[0].iconCode?.[iconIndex];

                return (
                    <Popover
                        key={index}
                        offset={-30}
                        placement="bottom"
                        backdrop="blur">
                        <PopoverTrigger>
                            <div key={index}
                                 className="flex flex-col items-center justify-between p-4 sm:-mb-12 mb-0 gap-y-2 sm:border-none border border-gray-200 border-opacity-25 text-center rounded-lg w-full sm:h-64 h-auto cursor-pointer">
                                <div className="flex flex-col items-center justify-start w-full h-1/3">
                                    <span className="uppercase">{part}</span>
                                    <span
                                        className="text-sm text-center">{weather.daypart[0].narrative[iconIndex].split('.')[0]}</span>
                                </div>
                                {iconCode ? (
                                    <Image
                                        src={`https://www.wunderground.com/static/i/c/v4/${iconCode}.svg`}
                                        width={100}
                                        height={100}
                                        className="w-20 h-20 p-2"
                                        alt={weather.wxPhraseLong}
                                    />
                                ) : (
                                    <span className="flex-1">?</span>
                                )}
                                <div className="flex justify-center w-full h-1/3">
                                    <span className="text-lg">{weather.daypart[0].temperature[iconIndex]}°</span>
                                </div>
                            </div>
                        </PopoverTrigger>
                        <PopoverContent className="p-4 text-lg">
                            {weather.daypart[0].narrative[iconIndex]}
                        </PopoverContent>
                    </Popover>
                );
            })}
        </div>
        {weather && alerts && <Alerts alerts={alerts} />}
    </div>);
};

export default Weather;