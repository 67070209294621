import {Button, ButtonProps, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader} from "@nextui-org/react";
import React from "react";

type ModalProps = {
    modalOpen: boolean;
    setModalOpen: ((isOpen: boolean) => void);
    title: string;
    message?: string;
    html?: React.ReactNode | undefined;
    button?: string | undefined;
    color?: ButtonProps['color'],
    onSubmit?: () => void | undefined,
    onClose?: () => void | undefined;
}

export const ConfirmationModal = ({
                                      modalOpen,
                                      setModalOpen,
                                      title,
                                      message,
                                      html,
                                      button,
                                      color,
                                      onSubmit,
                                      onClose
                                  }: ModalProps) => {
    return (<Modal
        isOpen={modalOpen}
        onOpenChange={setModalOpen}
        onKeyDown={(e) => {
            if (e.key === "Enter") {
                e.preventDefault();
            }
        }}
        onClose={onClose}
        placement="center"
        backdrop="blur"
        size={'xl'}
    >
        <ModalContent>
            <>
                <ModalHeader className="flex flex-col gap-1">{title}</ModalHeader>
                <ModalBody>
                    {message && <p>{message}</p>}
                    {html}
                </ModalBody>
                {button && color && onSubmit && (<ModalFooter>
                    <Button type="submit" color={color} onPress={onSubmit}>
                        {button}
                    </Button>
                </ModalFooter>)}
            </>
        </ModalContent>
    </Modal>)
}